/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import appConfig from 'src/appConfig';
import { Button, LoadingCommon, Text, View } from 'src/components/common';
import ConfirmationCodeField from 'src/components/ConfirmationCodeField';
// import { hideModal } from 'src/redux/modal/modalSlice';
import { useConfirmSignUp, useResendSignUp } from 'src/queries';
import { Callback } from 'src/redux/types';
import { ErrorService, Toastify } from 'src/services';
import { UAMBody } from '..';
import AccountCreatedSuccess from '../../AccountCreatedSuccess';

export type EmailConfirmationModalData = {
  value?: string;
  onResend?: (...args: any) => void;
  onSubmit?: (...args: any) => void;
  onSuccess?: (...args: any) => void;
};

const EmailConfirmationModal: React.FC<Props> = ({ handleClickBack, username }) => {
  const [counter, setCounter] = useState(15);

  const [isSucceed, setIsSucceed] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prev) => {
        if (prev > 0) {
          return prev - 1;
        }
      });
    }, 60 * 1000);
    //every 1 minute

    return () => clearInterval(timer);
  }, []);

  const resetInterval = () => setCounter(15);

  const { resendSignUp, isResendSignUp } = useResendSignUp({
    onSuccess() {
      Toastify.success('A new code has been sent to your email.');
      resetInterval();
    },
    onError(error) {
      Toastify.error(error.message);
    },
  });
  const { confirmSignUp, isConfirmSigningUp } = useConfirmSignUp({
    onSuccess() {
      setIsSucceed(true);
    },
    onError(error) {
      handleError(error);
    },
  });

  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleValueChange = (value: string) => {
    setCode(value);
  };

  const handleSubmitCode = () => {
    confirmSignUp({
      username,
      code,
    });
  };

  const handleResend = () => {
    resendSignUp({ username });
  };

  const handleError = (error: AuthError) => {
    const errorMessageMap = {
      [ErrorService.TYPES.CodeMismatchException]: ErrorService.MESSAGES.invalidCode,
      [ErrorService.TYPES.NotAuthorizedException]: ErrorService.MESSAGES.invalidCode,
    };

    setErrorMessage(errorMessageMap[error?.code.trim()] || error?.message);
  };

  const isDisabled = useMemo(() => code.length !== appConfig.VERIFICATION_CODE_LENGTH, [code]);

  return !isSucceed ? (
    <UAMBody title="Email Verification Code">
      <View className="ctn-verify">
        <Text className="color-gray-dark mb-32 ctn-verify__header">
          A verification code has been sent to your email. Enter the code below to verify your
          account.
        </Text>

        <View>
          <View className="ctn-verify__body">
            <ConfirmationCodeField onChange={handleValueChange} errorMessage={errorMessage} />

            <View justify="space-between" align="center" className="ctn-verify__body--info" isRow>
              <Text>{counter} minutes left</Text>
              {isResendSignUp ? (
                <LoadingCommon />
              ) : (
                <Button variant="link" onClick={handleResend}>
                  Resend
                </Button>
              )}
            </View>
            <Button
              isLoading={isConfirmSigningUp}
              disabled={isDisabled}
              className="mb-32"
              onClick={handleSubmitCode}
            >
              Verify
            </Button>
            <Button variant="link" onClick={handleClickBack}>
              Back to login
            </Button>
          </View>
        </View>
      </View>
    </UAMBody>
  ) : (
    <AccountCreatedSuccess />
  );
};

type Props = {
  username: string;
  handleClickBack: Callback;
};

export default EmailConfirmationModal;
